<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Timeline 2017-2018</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-calendar mg-r-5"></i><span class="breadcrumb-item active">Timeline 2017-2018</span></a>
                
            </div>
        </div> 

        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Atur Timeline
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Tahapan</label>
                                <input type="text" v-model="form.nama_kegiatan" value="" disabled class="form-control" placeholder="">
                            </div>
                            <hr>
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <label class="col-6">Timeline Normal<span class="text-danger">*</span></label>
                                    <label class="col-6">Timeline Maksimal<span class="text-danger">*</span></label>
                                    <div class="col-2">
                                        <select @change="valNormal()" v-model="form.start_normal" required="" class="form-control">
                                            <option v-for="item in jmlSemester" :key="item" :value="item">{{ item }}</option>
                                        </select>
                                    </div>
                                    <div class="col-1">
                                        -
                                    </div>
                                    <div class="col-2">
                                        <select  @change="valNormal()" v-model="form.end_normal" required="" class="form-control">
                                            <option v-for="item in jmlSemester" :key="item" :value="item">{{ item }}</option>
                                        </select>
                                    </div>
                                    <div class="col-1">
                                        ||
                                    </div>
                                    <div class="col-2">
                                        <select @change="valLimit()"  v-model="form.start_limit" required="" class="form-control">
                                            <option v-for="item in jmlSemester" :key="item" :value="item">{{ item }}</option>
                                        </select>
                                    </div>
                                    <div class="col-1">
                                        -
                                    </div>
                                    <div class="col-2">
                                        <select  @change="valLimit()" v-model="form.end_limit" required="" class="form-control">
                                            <option v-for="item in jmlSemester" :key="item" :value="item">{{ item }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-award mr-2"></span>14 Semester
                                        </div>
                                    </div>
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Tahapan...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th rowspan="2" class="text-center">No</th>
                                            <th rowspan="2" class="text-center">Tahapan</th>
                                            <th colspan="14" class="text-center">Semester</th>
                                            <th rowspan="2" style="width:5%" class="text-center"><i class="fe fe-settings"></i></th>
                                        </tr>
                                        <tr>
                                            <th v-for="item in jmlSemester" :key="item">
                                                {{ item }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="16">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(tahapan, index) in dataTahapan" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ tahapan.nama_kegiatan }}</td>
                                            <td v-for="(item, ind) in jmlSemester" :key="ind">
                                                <i v-if="ind >= (tahapan.start_normal-1) && ind < tahapan.end_normal" class="text-success fe fe-check-circle mr-2"></i>
                                                <br>
                                                <i v-if="ind >= (tahapan.start_limit-1) && ind < tahapan.end_limit" class="text-danger fe fe-check-circle mr-2"></i>
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-warning btn-sm" @click.prevent="editForm(tahapan.id)" ><i class="fe fe-edit"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                            <div class="card">
                            <div class="card-body">
                                Keterangan: <br>
                                <i class="text-success fe fe-check-circle mr-2"></i> Timeline Normal<br>
                                <i class="text-danger fe fe-check-circle mr-2"></i> Timeline Maksimal
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Timeline'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                jmlSemester: 14,
                dataTahapan: [],
                fetching: true,
                form: {
                    id: '',
                    nama_kegiatan: '',
                    start_normal: '',
                    end_normal: '',
                    start_limit: '',
                    end_limit: '',
                    id_tahapan: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData();
        },
        components: {
            vPagination
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            valNormal() {
                if(this.form.end_normal !== null && this.form.start_normal !== null){
                    if(this.form.end_normal < this.form.start_normal){
                        this.form.start_normal = this.form.end_normal-1;
                        this.$swal({
                            icon: 'error',
                            title: "error",
                            text: 'Batas Akhir Harus lebih besar',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }else if(this.form.start_normal > this.form.end_normal){
                        this.form.start_normal = this.form.end_normal-1;
                        this.$swal({
                            icon: 'error',
                            title: "error",
                            text: 'Waktu Awal Harus lebih kecil',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }
                }
            },
            valLimit() {
                if(this.form.end_limit !== null && this.form.start_limit !== null){
                    if(this.form.end_limit < this.form.start_limit){
                        this.form.start_limit = this.form.end_limit-1;
                        this.$swal({
                            icon: 'error',
                            title: "error",
                            text: 'Batas Akhir Harus lebih besar',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }else if(this.form.start_limit > this.form.end_limit){
                        this.form.start_limit = this.form.end_limit-1;
                        this.$swal({
                            icon: 'error',
                            title: "error",
                            text: 'Waktu Awal Harus lebih kecil',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }
                }
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/timeline', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataTahapan = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // save periode
            submitForm() {
                this.$http.post(this.baseUrl + 'admin/timeline/save', this.form)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data Tahapan berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$modal.hide('my-modal')
                        this.getData()
                        this.form.nama_kegiatan = ''
                        this.form.start_normal  = '';
                        this.form.end_normal    = '';
                        this.form.start_limit   = '';
                        this.form.end_limit     = '';
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/timeline/detail?id=${id}`)
                    .then((response) => {
                        this.form.id            = response.data.data[0].id;
                        this.form.id_tahapan    = response.data.data[0].id_tahapan;
                        this.form.nama_kegiatan = response.data.data[0].nama_kegiatan;
                        this.form.start_normal  = response.data.data[0].start_normal;
                        this.form.end_normal    = response.data.data[0].end_normal;
                        this.form.start_limit   = response.data.data[0].start_limit;
                        this.form.end_limit     = response.data.data[0].end_limit;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>